<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="info-accident-business"
      typeCss="active-full"
      @setActive="setActive"
    />

    <div class="page-container">
      <validation-observer ref="tabAccount">
        <info-accident
          v-if="isRendered('info-accident-business')"
          id="user-add"
          :accidentId="accidentId"
          :dataInfo="dataInfo"
          class="tab-pane"
          :class="{'active':activeItem==='info-accident-business'}"
        />
        <table-workers
          v-if="isRendered('workers')"
          id="user-add"
          :accidentId="accidentId"
          class="tab-pane"
          :class="{'active':activeItem==='workers'}"
        />
      </validation-observer>
      <div
        v-if="['declare-accident-edit', 'declare-accident-create'].includes($route.name)"
        class="mt-2"
      >
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="hadnleCreate($event)"
        >
          Lưu lại
        </b-button>
        <b-button
          variant="outline-primary"
          type="reset"
          @click="backUser"
        >
          Quay lại
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import {
  BButton,
}
  from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import InfoAccident from './components/InfoAccident.vue'
import TableWorkers from './components/TableWorkers.vue'
import eventBus from '@/libs/event-bus'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    MyTabs,
    ValidationObserver,
    ValidationProvider,
    InfoAccident,
    BButton,
    TableWorkers,
  },
  data() {
    return {
      required,
      activeItem: 'info-accident-business',
      listTabs: [
        {
          key: 'info-accident-business',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: true,
        },
        {
          key: 'workers',
          title: 'Người lao động bị nạn',
          icon: 'UserIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      dataInfo: {
        code: '',
        accidentTime: '',
        location: '',
        accidentReasonId: '',
        accidentType: '',
        provinceId: 0,
        districtId: 0,
        wardId: 0,
        description: '',
        isSubsidize: false,
        fileName: null,
        modelFormData: null,
        isCriminalProsecution: false,
        isInvestigate: false,
        isEvaluation: false,
        workerReturn: 0,
      },
      accidentId: null,
      dataAccidentId: null,
    }
  },
  watch: {
    dataAccidentId(val) {
      if (val) {
        this.dataInfo = {
          code: this.dataAccidentId.code,
          accidentTime: this.dataAccidentId.accidentTime,
          location: this.dataAccidentId.location,
          accidentReasonId: this.dataAccidentId.accidentReasonId,
          accidentType: this.dataAccidentId.accidentType,
          provinceId: this.dataAccidentId.provinceId,
          districtId: this.dataAccidentId.districtId,
          wardId: this.dataAccidentId.wardId,
          description: this.dataAccidentId.description,
          isSubsidize: this.dataAccidentId.isSubsidize,
          fileName: this.dataAccidentId.fileName,
          isInvestigate: this.dataAccidentId.isInvestigate,
          isCriminalProsecution: this.dataAccidentId.isCriminalProsecution,
          isEvaluation: this.dataAccidentId.isEvaluation,
          workerReturn: this.dataAccidentId.workerReturn,

        }
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchAccidentId(this.$route.params.id)
    }
    eventBus.$on('handlerAddAccident', this.hadnleCreate)
    eventBus.$on('getDataEdit', this.fetchAccidentId)
    eventBus.$on('resetData', this.resetData)
  },
  destroyed() {
    eventBus.$off('handlerAddAccident', this.hadnleCreate)
    eventBus.$off('getDataEdit', this.fetchAccidentId)
    eventBus.$off('resetData', this.resetData)
  },
  methods: {
    resetData() {
      this.dataInfo = {
        code: '',
        accidentTime: '',
        location: '',
        accidentReasonId: '',
        accidentType: '',
        provinceId: 0,
        districtId: 0,
        wardId: 0,
        description: '',
        isSubsidize: false,
        fileName: null,
        modelFormData: null,
        isCriminalProsecution: false,
        isInvestigate: false,
        isEvaluation: false,
        workerReturn: 0,
      }
    },

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },

    setActive(tabActive) {
      this.activeItem = tabActive
      const tab = this.listTabs.find(element => element.key === tabActive)
      tab.isRendered = true
    },

    // Lấy thông tin khai báo tai nạn lao động theo id
    async fetchAccidentId(accidentId) {
      this.accidentId = accidentId
      this.listTabs[1].isDisabled = false
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_ACCIDENT_BUSINESS}${accidentId}`)
      this.dataAccidentId = data
    },

    // Trở về danh sách
    backUser() {
      this.$router.push({ name: 'declare-accident' })
    },

    // check string null
    removeDataEmpty(value) {
      return ![undefined, null, ''].includes(value) ? value : ''
    },

    // Action tạo khai báo tai nạn lao động
    async hadnleCreate(bvModalEvt) {
      const formData = new FormData()
      if (this.dataInfo.modelFormData) {
        formData.append('Code', this.dataInfo.code)
        formData.append('AccidentReasonId', this.dataInfo.accidentReasonId)
        formData.append('AccidentType', this.dataInfo.accidentType)
        formData.append('AccidentTime', this.dataInfo.accidentTime)
        formData.append('Location', this.dataInfo.location)
        formData.append('ProvinceId', this.dataInfo.provinceId)
        formData.append('DistrictId', this.dataInfo.districtId)
        formData.append('WardId', this.dataInfo.wardId)
        formData.append('Description', this.removeDataEmpty(this.dataInfo.description))
        formData.append('IsSubsidize', this.dataInfo.isSubsidize)
        formData.append('IsSecure', this.dataInfo.modelFormData.isSecure)
        formData.append('formFile', this.dataInfo.modelFormData.files)
        formData.append('FileName', this.dataInfo.fileName)
        formData.append('IsInvestigate', this.dataInfo.isInvestigate)
        formData.append('IsCriminalProsecution', this.dataInfo.isCriminalProsecution)
        formData.append('IsEvaluation', this.dataInfo.isEvaluation)
        formData.append('WorkerReturn', this.dataInfo.workerReturn)
      } else {
        formData.append('Code', this.dataInfo.code)
        formData.append('AccidentReasonId', this.dataInfo.accidentReasonId)
        formData.append('AccidentType', this.dataInfo.accidentType)
        formData.append('AccidentTime', this.dataInfo.accidentTime)
        formData.append('Location', this.dataInfo.location)
        formData.append('ProvinceId', this.dataInfo.provinceId)
        formData.append('DistrictId', this.dataInfo.districtId)
        formData.append('WardId', this.dataInfo.wardId)
        formData.append('Description', this.removeDataEmpty(this.dataInfo.description))
        formData.append('IsSubsidize', this.dataInfo.isSubsidize)
        formData.append('IsInvestigate', this.dataInfo.isInvestigate)
        formData.append('IsCriminalProsecution', this.dataInfo.isCriminalProsecution)
        formData.append('IsEvaluation', this.dataInfo.isEvaluation)
        formData.append('WorkerReturn', this.dataInfo.workerReturn)
      }
      if (this.$route.params.id || this.accidentId) {
        formData.append('Id', this.$route.params.id || this.accidentId)
        // debugger
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.put(ConstantsApi.EDIT_ACCIDENT_BUSINESS, formData).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Cập nhật thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                if (this.$route.name === 'declare-accident-edit') {
                  this.$router.push({ name: 'declare-accident' })
                } else {
                  eventBus.$emit('reloadTable')
                }
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      } else {
        bvModalEvt.preventDefault()
        this.$refs.tabAccount.validate().then(async success => {
          if (success) {
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.CREATE_ACCIDENT_BUSINESS, formData).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Tạo thành công', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
                this.accidentId = res.data?.data?.id
                if (this.$route.name === 'declare-accident-create') {
                  this.$router.push({ name: 'declare-accident-edit', params: { id: this.accidentId } })
                } else {
                  eventBus.$emit('reloadTable')
                }
                this.listTabs[1].isDisabled = false
              }
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        })
      }
    },
  },
}
</script>
